import {AutomaticInteractionStatus} from 'src/domain/enums/automatic-interaction.status';

const API_ENDPOINTS_HOST = {
  REACT_APP_API_MASTER_DATA: 'REACT_APP_API_MASTER_DATA',
  REACT_APP_API_ADMIN_AUTH: 'REACT_APP_API_ADMIN_AUTH',
  REACT_APP_API_AUTH: 'REACT_APP_API_AUTH',
  REACT_APP_API_CMS: 'REACT_APP_API_CMS',
  REACT_APP_API_CDP: 'REACT_APP_API_CDP',
  REACT_APP_API_GAME: 'REACT_APP_API_GAME',
  REACT_APP_IDENTITY_ADDRESS: 'REACT_APP_IDENTITY_ADDRESS',
  REACT_APP_API_TRANSACTION: 'REACT_APP_API_TRANSACTION',
  REACT_APP_API_CRM: 'REACT_APP_API_CRM',
  REACT_APP_API_DOCUMENT: 'REACT_APP_API_DOCUMENT',
  REACT_APP_API_GIFT: 'REACT_APP_API_GIFT',
  REACT_APP_API_ADS: 'REACT_APP_API_ADS',
  REACT_APP_API_ACTIVITY: 'REACT_APP_API_ACTIVITY',

  REACT_APP_API_VIEON: 'REACT_APP_API_VIEON',

  REACT_APP_API_REPORT: 'REACT_APP_API_REPORT',
};

const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const STORAGE_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  MERCHANT_ID: 'MERCHANT_ID',
  USER_INFO_DATA: 'USER_INFO_DATA',
};

const MERCHANT_PERMISSIONS = {
  AdminManagementCreate: 'AdminManagementCreate',
  AdminManagementRead: 'AdminManagementRead',
  AdminManagementUpdate: 'AdminManagementUpdate',
  AdminManagementDelete: 'AdminManagementDelete',
  AdminManagementImport: 'AdminManagementImport',
  EarningAndBurningDashboardRead: 'EarningAndBurningDashboardRead',
  MerchantContentCreate: 'MerchantContentCreate',
  MerchantContentUpdate: 'MerchantContentUpdate',
  MerchantContentDelete: 'MerchantContentDelete',
  MerchantContentRead: 'MerchantContentRead',
  MerchantContentSettingCreate: 'MerchantContentSettingCreate',
  MerchantContentSettingDelete: 'MerchantContentSettingDelete',
  MerchantContentSettingRead: 'MerchantContentSettingRead',
  MerchantContentSettingUpdate: 'MerchantContentSettingUpdate',
  MerchantDashboard: 'MerchantDashboard',
  MerchantGiftCreate: 'MerchantGiftCreate',
  MerchantGiftDelete: 'MerchantGiftDelete',
  MerchantGiftRead: 'MerchantGiftRead',
  MerchantGiftUpdate: 'MerchantGiftUpdate',
  MissionCreate: 'MissionCreate',
  MissionDelete: 'MissionDelete',
  MissionRead: 'MissionRead',
  MissionUpdate: 'MissionUpdate',
  ProductCodeExport: 'ProductCodeExport',
  ProductCodeRead: 'ProductCodeRead',
  ProductCodeUsedExport: 'ProductCodeUsedExport',
  ProductCodeUsedRead: 'ProductCodeUsedRead',
  ProductCreate: 'ProductCreate',
  ProductDelete: 'ProductDelete',
  ProductGenerate: 'ProductGenerate',
  ProductImport: 'ProductImport',
  ProductLoyaltyProgramSettingCreate: 'ProductLoyaltyProgramSettingCreate',
  ProductLoyaltyProgramSettingRead: 'ProductLoyaltyProgramSettingRead',
  ProductLoyaltyProgramSettingUpdate: 'ProductLoyaltyProgramSettingUpdate',
  ProductRead: 'ProductRead',
  ProductUpdate: 'ProductUpdate',
  UserDashboardRead: 'UserDashboardRead',
  UserExchangedGiftDashboardRead: 'UserExchangedGiftDashboardRead',
  UserManagementExport: 'UserManagementExport',
  UserManagementRead: 'UserManagementRead',
  UserManagementEdit: 'UserManagementEdit',
  UserTransactionDashboardRead: 'UserTransactionDashboardRead',
  VoucherImport: 'VoucherImport',
  VoucherRead: 'VoucherRead',
  MerchantTransactionPointRead: 'MerchantTransactionPointRead',
  MerchantTransactionGiftRead: 'MerchantTransactionGiftRead',
  MerchantNotificationRead: 'MerchantNotificationRead',
  MerchantNotificationCreate: 'MerchantNotificationCreate',
  MerchantNotificationUpdate: 'MerchantNotificationUpdate',
  MerchantNotificationDelete: 'MerchantNotificationDelete',
  MerchantNotificationReportRead: 'MerchantNotificationReportRead',
  MerchantNotificationReview: 'MerchantNotificationReview',
  MerchantTicketSettingsRead: 'MerchantTicketSettingsRead',
  MerchantTicketSettingsCreate: 'MerchantTicketSettingsCreate',
  MerchantTicketSettingsUpdate: 'MerchantTicketSettingsUpdate',
  MerchantTicketSettingsDelete: 'MerchantTicketSettingsDelete',
  MerchantTicketListRead: 'MerchantTicketListRead',
  MerchantTicketListCreate: 'MerchantTicketListCreate',
  MerchantTicketListUpdate: 'MerchantTicketListUpdate',
  MerchantTicketListExport: 'MerchantTicketListExport',
  TicketManagementMenuRead: 'TicketManagementMenuRead',
  MerchantDashboardMenuRead: 'MerchantDashboardMenuRead',
  MerchantUserDashboardRead: 'MerchantUserDashboardRead',
  MerchantTicketManagementMenuRead: 'MerchantTicketManagementMenuRead',
  MerchantEarningAndBurningDashboardRead: 'MerchantEarningAndBurningDashboardRead',
  MerchantUserExchangedGiftDashboardRead: 'MerchantUserExchangedGiftDashboardRead',
  MerchantUserTransactionDashboardRead: 'MerchantUserTransactionDashboardRead',
  MerchantUserRelationRateDashboardRead: 'MerchantUserRelationRateDashboardRead',
  MerchantUserDashboardExport: 'MerchantUserDashboardExport',
  MerchantEarningAndBurningDashboardExport: 'MerchantEarningAndBurningDashboardExport',
  MerchantUserExchangedGiftDashboardExport: 'MerchantUserExchangedGiftDashboardExport',
  MerchantUserTransactionDashboardExport: 'MerchantUserTransactionDashboardExport',
  MerchantUserRelationRateDashboardExport: 'MerchantUserRelationRateDashboardExport',
  MerchantAccessGroupRead: 'MerchantAccessGroupRead',
  MerchantAccessGroupCreate: 'MerchantAccessGroupCreate',
  MerchantAccessGroupUpdate: 'MerchantAccessGroupUpdate',
  MerchantAccessGroupDelete: 'MerchantAccessGroupDelete',
  MerchantDeliveryTransactionRead: 'DeliveryTransactionRead',
  PointManagementMenuRead: 'PointManagementMenuRead',
  PointManagementRead: 'PointManagementRead',
  PointManagementReview: 'PointManagementReview',
  PointManagementCreate: 'PointManagementCreate',
  PointManagementUpdate: 'PointManagementUpdate',
  BillUploadRead: 'BillUploadRead',
  BillUploadReview: 'BillUploadReview',
  CheckInTransactionRead: 'CheckInTransactionRead',
  MerchantRetailTransactionRead: 'MerchantRetailTransactionRead',
  MerchantRetailTransactionExport: 'MerchantRetailTransactionExport',
  ProductsGroupDelete: 'ProductsGroupDelete',
  ProductsGroupUpdate: 'ProductsGroupUpdate',
  ProductsGroupCreate: 'ProductsGroupCreate',
  ProductsGroupRead: 'ProductsGroupRead',
  MerchantInchargeTicket: 'MerchantInchargeTicket',
  MerchantHandleChat: 'MerchantHandleChat',

  GiftOnDemandImport: 'GiftOnDemandImport',
  GiftOnDemandUpdate: 'GiftOnDemandUpdate',
  GiftOnDemandDelete: 'GiftOnDemandDelete',
  GiftOnDemandCreate: 'GiftOnDemandCreate',
  GiftOnDemandRead: 'GiftOnDemandRead',
  GiftOnDemandExport: 'GiftOnDemandExport',
  GiftNotificationRead: 'GiftNotificationRead',
  GiftNotificationCreate: 'GiftNotificationCreate',
  GiftNotificationUpdate: 'GiftNotificationUpdate',
  GiftNotificationDelete: 'GiftNotificationDelete',
  MerchantReportMenuRead: 'MerchantReportMenuRead',
  MerchantReportInputOutputExport: 'MerchantReportInputOutputExport',
  MerchantReportStorageCardDetailRead: 'MerchantReportStorageCardDetailRead',
  MerchantReportStorageCardDetailExport: 'MerchantReportStorageCardDetailExport',
  GiftCardRecoveryRead: 'GiftCardRecoveryRead',
  GiftCardRecoveryCreate: 'GiftCardRecoveryCreate',
  GiftCardRecoveryUpdate: 'GiftCardRecoveryUpdate',
  GiftCardRecoveryDelete: 'GiftCardRecoveryDelete',
  GiftCardRecoveryExport: 'GiftCardRecoveryExport',

  MerchantReportCardRecallRead: 'MerchantReportCardRecallRead',
  MerchantReportCardRecallExport: 'MerchantReportCardRecallExport',
  MerchantReportCardRecallDetailRead: 'MerchantReportCardRecallDetailRead',
  MerchantReportCardRecallDetailExport: 'MerchantReportCardRecallDetailExport',
  MerchantReportChangeGiftRead: 'MerchantReportChangeGiftRead',
  MerchantReportChangeGiftExport: 'MerchantReportChangeGiftExport',
  MerchantReportChangeGiftTotalRead: 'MerchantReportChangeGiftTotalRead',
  MerchantReportChangeGiftTotalExport: 'MerchantReportChangeGiftTotalExport',

  MerchantReportSumGiftCustomerRead: 'MerchantReportSumGiftCustomerRead',
  MerchantReportSumGiftCustomerExport: 'MerchantReportSumGiftCustomerExport',
  MerchantReportGiftCustomerRead: 'MerchantReportGiftCustomerRead',
  MerchantReportGiftCustomerExport: 'MerchantReportGiftCustomerExport',
  MerchantReportGiftForSaleManRead: 'MerchantReportGiftForSaleManRead',
  MerchantReportGiftForSaleManExport: 'MerchantReportGiftForSaleManExport',
  MerchantReportGiftForSaleManDetailRead: 'MerchantReportGiftForSaleManDetailRead',
  MerchantReportGiftForSaleManDetailExport: 'MerchantReportGiftForSaleManDetailExport',
  MerchantGiftReportRead: 'MerchantGiftReportRead',
  MerchantGiftReportSendMail: 'MerchantGiftReportSendMail',
  MerchantLoyaltyProgramTierRead: 'MerchantLoyaltyProgramTierRead',
  MerchantLoyaltyProgramTierCreate: 'MerchantLoyaltyProgramTierCreate',
  MerchantLoyaltyProgramTierUpdate: 'MerchantLoyaltyProgramTierUpdate',
  MerchantLoyaltyProgramConfigRewardRead: 'MerchantLoyaltyProgramConfigRewardRead',
  MerchantLoyaltyProgramConfigRewardCreate: 'MerchantLoyaltyProgramConfigRewardCreate',
  MerchantLoyaltyProgramConfigRewardUpdate: 'MerchantLoyaltyProgramConfigRewardUpdate',
  MerchantLoyaltyProgramEarningRuleRead: 'MerchantLoyaltyProgramEarningRuleRead',
  MerchantLoyaltyProgramEarningRuleCreate: 'MerchantLoyaltyProgramEarningRuleCreate',
  MerchantLoyaltyProgramEarningRuleUpdate: 'MerchantLoyaltyProgramEarningRuleUpdate',
  MerchantLoyaltyProgramActivityRead: 'MerchantLoyaltyProgramActivityRead',
  MerchantLoyaltyProgramActivityCreate: 'MerchantLoyaltyProgramActivityCreate',
  MerchantLoyaltyProgramActivityUpdate: 'MerchantLoyaltyProgramActivityUpdate',
  MerchantLoyaltyProgramGiftRead: 'MerchantLoyaltyProgramGiftRead',
  MerchantLoyaltyProgramGiftCreate: 'MerchantLoyaltyProgramGiftCreate',
  MerchantLoyaltyProgramGiftUpdate: 'MerchantLoyaltyProgramGiftUpdate',
  MerchantLoyaltyProgramCategoryRead: 'MerchantLoyaltyProgramCategoryRead',
  MerchantLoyaltyProgramCategoryCreate: 'MerchantLoyaltyProgramCategoryCreate',
  MerchantLoyaltyProgramCategoryUpdate: 'MerchantLoyaltyProgramCategoryUpdate',
  MerchantLoyaltyProgramPlatformRead: 'MerchantLoyaltyProgramPlatformRead',
  MerchantLoyaltyProgramPlatformCreate: 'MerchantLoyaltyProgramPlatformCreate',
  MerchantLoyaltyProgramPlatformUpdate: 'MerchantLoyaltyProgramPlatformUpdate',
  MerchantLoyaltyProgramGiftsVoucherVieONRead: 'MerchantLoyaltyProgramGiftsVoucherVieONRead',
  ImportTransactionCreate: 'ImportTransactionCreate',
  ImportTransactionRead: 'ImportTransactionRead',
  ImportUserCreate: 'ImportUserCreate',
  ImportUserRead: 'ImportUserRead',
  VieonDashboardRead: 'VieonDashboardRead',
  MerchantStoreRead: 'MerchantStoreRead',
  MerchantStoreCreate: 'MerchantStoreCreate',
  MerchantStoreUpdate: 'MerchantStoreUpdate',
  MerchantStoreDelete: 'MerchantStoreDelete',
  MerchantStoreReview: 'MerchantStoreReview',
  MerchantStoreImport: 'MerchantStoreImport',
  PointManagementListMenuRead: 'PointManagementListMenuRead',
  PointManagementListRead: 'PointManagementListRead',
  AdminUserPermissionManagementRead: 'AdminUserPermissionManagementRead',
  AdminUserPermissionManagementCreate: 'AdminUserPermissionManagementCreate',
  AdminUserPermissionManagementUpdate: 'AdminUserPermissionManagementUpdate',
  AdminUserPermissionManagementDelete: 'AdminUserPermissionManagementDelete',
  CustomerDataManagementRead: 'CustomerDataManagementRead',
  CustomerDataManagementCreate: 'CustomerDataManagementCreate',
  CustomerDataManagementUpdate: 'CustomerDataManagementUpdate',
  CustomerDataManagementDelete: 'CustomerDataManagementDelete',
  CustomerFilterManagementRead: 'CustomerFilterManagementRead',
  CustomerFilterManagementCreate: 'CustomerFilterManagementCreate',
  CustomerFilterManagementUpdate: 'CustomerFilterManagementUpdate',
  CustomerFilterManagementImport: 'CustomerFilterManagementImport',
  CustomerFilterManagementDelete: 'CustomerFilterManagementDelete',

  MerchantMotulScanQRCodeTransactionRead: 'MerchantMotulScanQRCodeTransactionRead',
  MerchantMotulScanQRCodeTransactionExport: 'MerchantMotulScanQRCodeTransactionExport',
  MerchantMotulRedeemTransactionRead: 'MerchantMotulRedeemTransactionRead',
  MerchantMotulRedeemTransactionExport: 'MerchantMotulRedeemTransactionExport',
  MerchantMotulMonthlyPaymentRead: 'MerchantMotulMonthlyPaymentRead',
  MerchantMotulScanQRCodeAccumulateTransactionRead:
    'MerchantMotulScanQRCodeAccumulateTransactionRead',
  MerchantMotulScanQRCodeAccumulateTransactionExport:
    'MerchantMotulScanQRCodeAccumulateTransactionExport',

  MerchantIDPStoreJoinGameRead: 'MerchantIDPStoreJoinGameRead',
  MerchantIDPStoreJoinGameImport: 'MerchantIDPStoreJoinGameImport',
  MerchantIDPStoreJoinGameExport: 'MerchantIDPStoreJoinGameExport',
  MerchantIDPTurnOfPlayManagementRead: 'MerchantIDPTurnOfPlayManagementRead',
  MerchantIDPTurnOfPlayManagementImport: 'MerchantIDPTurnOfPlayManagementImport',
  MerchantIDPTurnOfPlayManagementExport: 'MerchantIDPTurnOfPlayManagementExport',
  MerchantIDPApprovalChangePhoneNumberRead: 'MerchantIDPApprovalChangePhoneNumberRead',
  MerchantIDPApprovalChangePhoneNumberApproval: 'MerchantIDPApprovalChangePhoneNumberApproval',
  MerchantIDPApprovalChangePhoneNumberDelete: 'MerchantIDPApprovalChangePhoneNumberDelete',

  MerchantAutomaticInteractionRead: 'MerchantAutomaticInteractionRead',
  MerchantAutomaticInteractionCreate: 'MerchantAutomaticInteractionCreate',
  MerchantAutomaticInteractionUpdate: 'MerchantAutomaticInteractionUpdate',
  MerchantAutomaticInteractionDelete: 'MerchantAutomaticInteractionDelete',
  MerchantAutomaticInteractionAccountSmsZnsRead: 'MerchantAutomaticInteractionAccountSmsZnsRead',
  MerchantAutomaticInteractionAccountSmsZnsCreate:
    'MerchantAutomaticInteractionAccountSmsZnsCreate',
  MerchantAutomaticInteractionAccountSmsZnsUpdate:
    'MerchantAutomaticInteractionAccountSmsZnsUpdate',
  MerchantAutomaticInteractionAccountSmsZnsDelete:
    'MerchantAutomaticInteractionAccountSmsZnsDelete',
};

const STORE_TYPE = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

const CLASSIFIED_GIFT = [
  {label: 'Quà để Chill', value: 'CHILL_GIFT'},
  {label: 'E-voucher', value: 'E_VOUCHER'},
  {label: 'Quà cửa hàng', value: 'STORE_GIFT'},
];

const DELIVERY_TRANSACTION_PICKUP_TYPE = [
  {label: 'Thu gom tận nơi', value: 1},
  {label: 'Gửi hàng tại bưu cục', value: 2},
];

const DELIVERY_TRANSACTION_SERVICE_NAME = [
  {label: 'Chuyển phát nhanh', value: 'EMS'},
  {label: 'Chuyển phát thường', value: 'BK'},
  {label: 'Dịch vụ thỏa thuận riêng giữa khách hàng và VNPost', value: 'DONG_GIA'},
  {label: 'TMĐT-Chuyển phát nhanh EMS', value: 'TMDT_EMS'},
  {label: 'TMĐT-Chuyển phát tiêu chuẩn', value: 'TMDT_BK'},
  {label: 'TMĐT-Chuyển phát nhanh EMS tiết kiệm (liên vùng)', value: 'TMDT_EMS_TK'},
];

const MERCHANT_NAME_OF_STRONGBOW = 'strongbow';
const STATUS_COLOR = (status) => {
  let name = '';
  switch (status) {
    case 'GIFT_REJECTED':
    case 'CONTENT_REJECTED':
    case 'CONTENT_SETTING_REJECTED':
    case 'NOTI_SETTING_REJECTED':
    case 'NOTI_GIFT_REJECTED':
    case 'CANCELED_TICKET':
      name = 'bg-danger';
      break;
    case 'GIFT_PENDING':
    case 'CONTENT_PENDING':
    case 'CONTENT_SETTING_PENDING':
    case 'NOTI_SETTING_PENDING':
    case 'GIFT_PENDING_REMOVED_ON_APP':
    case 'CONTENT_PENDING_REMOVED_ON_APP':
    case 'CONTENT_SETTING_PENDING_REMOVED_ON_APP':
    case 'NOTI_SETTING_PENDING_REMOVED_ON_APP':
    case 'GAME_SETTING_PENDING_REMOVE_ON_APP':
    case 'CONTENT_SETTING_PENDING_REMOVE_ON_APP':
    case 'CONTENT_PENDING_REMOVE_ON_APP':
    case 'NOTI_GIFT_PENDING':
    case 'NEW_TICKET':
    case 'RE_OPEN_TICKET':
    case 'WAITING_FOR_RESPONSE':
    case 'PENDING_TICKET':
    case 'RECEIVED_TICKET':
    case 'NEW_TICKET_MESSAGE':
      name = 'bg-warning';
      break;
    case 'GIFT_APPROVED':
    case 'CONTENT_APPROVED':
    case 'CONTENT_SETTING_APPROVED':
    case 'NOTI_SETTING_APPROVED':
    case 'NOTI_GIFT_APPROVED':
    case 'COMPLETED':
      name = 'bg-success';
      break;
    case 'NOTI_SETTING_REMOVED_ON_APP':
    case 'GIFT_REMOVED_ON_APP':
    case 'CONTENT_REMOVED_ON_APP':
    case 'CONTENT_SETTING_REMOVED_ON_APP':
      name = 'bg-remove-on-ap';
      break;
    default:
      name = 'bg-success';
      break;
  }
  return name;
};
const PROCESSING_STATUS = [
  {
    id: 'GIFT_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'GIFT_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'GIFT_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'GIFT_PENDING_REMOVED_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
  },
  {
    id: 'GIFT_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
  },
  {
    id: 'LOYALTY_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'LOYALTY_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'LOYALTY_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'REWARD_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'REWARD_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'REWARD_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'EXCHANGE_REWARD_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'EXCHANGE_REWARD_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'EXCHANGE_REWARD_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'NOTI_SETTING_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'NOTI_SETTING_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'NOTI_SETTING_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'CONTENT_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'CONTENT_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'CONTENT_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'CONTENT_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
  },
  {
    id: 'CONTENT_PENDING_REMOVE_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
  },
  {
    id: 'CONTENT_SETTING_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'CONTENT_SETTING_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'CONTENT_SETTING_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'CONTENT_SETTING_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
  },
  {
    id: 'CONTENT_SETTING_PENDING_REMOVE_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
  },
  {
    id: 'GAME_SETTING_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'GAME_SETTING_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'GAME_SETTING_REJECTED',
    label: 'Đã từ chối',
  },
  {
    id: 'GAME_SETTING_REMOVED_ON_APP',
    label: 'Đã gỡ khỏi ứng dụng',
  },
  {
    id: 'GAME_SETTING_PENDING_REMOVE_ON_APP',
    label: 'Chờ gỡ khỏi ứng dụng',
  },
  {
    id: 'NEW_TICKET',
    label: 'Tạo mới',
  },
  {
    id: 'RE_OPEN_TICKET',
    label: 'Mở lại',
  },
  {
    id: 'NEW_TICKET_MESSAGE',
    label: 'Thông báo mới từ user',
  },
  {
    id: 'CANCELED_TICKET',
    label: 'Hủy',
  },
  {
    id: 'PENDING_TICKET',
    label: 'Đang xử lý',
  },
  {
    id: 'WAITING_FOR_RESPONSE',
    label: 'Chờ khách hàng phản hồi',
  },
  {
    id: 'COMPLETED',
    label: 'Hoàn thành',
  },
  {
    id: 'NOTI_GIFT_PENDING',
    label: 'Chờ duyệt',
  },
  {
    id: 'NOTI_GIFT_APPROVED',
    label: 'Đã duyệt',
  },
  {
    id: 'NOTI_GIFT_REJECTED',
    label: 'Từ chối',
  },
];
const NOTIFICATION_TYPE_ENDPOINT = [
  {
    id: 'GIFT',
    value: '/gift/',
  },
  {
    id: 'LOYALTY',
    value: '/loyalty/',
  },
  {
    id: 'REWARD',
    value: '/reward/',
  },
  {
    id: 'EXCHANGE_REWARD',
    value: '/exchange-fee/',
  },
  {
    id: 'GAME_SETTING',
    value: '/game-setting/',
  },
  {
    id: 'TICKET',
    value: '/list-tickets/',
  },
  {
    id: 'CONTENT',
    value: '/content/',
  },
  {
    id: 'NOTI_SETTING',
    value: '/notification/',
  },
  {
    id: 'CONTENT_SETTING',
    value: '/content-setting/',
  },
  {
    id: 'NOTI_GIFT',
    value: '/gift-notification/',
  },
  {
    id: 'TICKET',
    value: '/list-tickets/',
  },
];

const REDIRECT_PATHS = {
  GIFT: 'gift',
  CONTENT: 'content',
  CONTENT_SETTING: 'content-setting',
  TICKET: 'list-tickets',
};

const AUDIT_LOG_TYPE = {
  GIFT: 'GIFT',
  CONTENT: 'CONTENT',
  CONTENT_SETTING: 'CONTENT_SETTING',
  NOTI_SETTING: 'NOTIFICATION_SETTING',
  TICKET: 'TICKET',
};

const MERCHANT_PORTAL_DASHBOARD_TYPE = {
  MERCHANT_PORTAL_USER_INFO_DASHBOARD: 'MERCHANT_PORTAL_EXPORT_USER_INFO_DASHBOARD',
  MERCHANT_PORTAL_USER_INFO_RAW: 'MERCHANT_PORTAL_EXPORT_USER_INFO_RAW',
  MERCHANT_PORTAL_RETAIL_TRANSACTION_DASHBOARD:
    'MERCHANT_PORTAL_EXPORT_RETAIL_TRANSACTION_DASHBOARD',
  MERCHANT_PORTAL_RETAIL_TRANSACTION_RAW: 'MERCHANT_PORTAL_EXPORT_RETAIL_TRANSACTION_RAW',
  STORE_REWARD_TRANSACTION_DASHBOARD: 'STORE_REWARD_TRANSACTION_DASHBOARD',
  MERCHANT_PORTAL_REWARD_TRANSACTION_DASHBOARD:
    'MERCHANT_PORTAL_EXPORT_REWARD_TRANSACTION_DASHBOARD',
  MERCHANT_PORTAL_REWARD_TRANSACTION_RAW: 'MERCHANT_PORTAL_EXPORT_REWARD_TRANSACTION_RAW',
  MERCHANT_PORTAL_RELATION_RATE_DASHBOARD: 'MERCHANT_PORTAL_EXPORT_RELATION_RATE_DASHBOARD',
};

const MONTH_OPTIONS = [
  {
    label: 'Tháng 1',
    value: '01',
  },
  {
    label: 'Tháng 2',
    value: '02',
  },
  {
    label: 'Tháng 3',
    value: '03',
  },
  {
    label: 'Tháng 4',
    value: '04',
  },
  {
    label: 'Tháng 5',
    value: '05',
  },
  {
    label: 'Tháng 6',
    value: '06',
  },
  {
    label: 'Tháng 7',
    value: '07',
  },
  {
    label: 'Tháng 8',
    value: '08',
  },
  {
    label: 'Tháng 9',
    value: '09',
  },
  {
    label: 'Tháng 10',
    value: '10',
  },
  {
    label: 'Tháng 11',
    value: '11',
  },
  {
    label: 'Tháng 12',
    value: '12',
  },
];

const GIFT_TIME_OPTION_VALUE = {
  START_REDEEM_BY_SPECIFIC_DATETIME: 0,
  END_REDEEM_BY_SPECIFIC_DATETIME: 0,

  START_USAGE_BY_SPECIFIC_DATETIME: 0,
  START_USAGE_BY_SPECIFIC_EVENT: 1,
  START_USAGE_EVENT_BY_RECEIVED_DAY: 0,

  END_USAGE_BY_SPECIFIC_DATETIME: 0,
  END_USAGE_BY_DURATION: 1,
  END_USAGE_BY_TIMELINE: 2,
  END_USAGE_DURATION_BY_DATE: 'DATE',
  END_USAGE_DURATION_BY_WEEK: 'WEEK',
  END_USAGE_DURATION_BY_MONTH: 'MONTH',
  END_USAGE_DURATION_BY_YEAR: 'YEAR',
};

const GIFT_TIME_START_REDEEM_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.START_REDEEM_BY_SPECIFIC_DATETIME},
];

const GIFT_TIME_END_REDEEM_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.END_REDEEM_BY_SPECIFIC_DATETIME},
];

const GIFT_TIME_START_USAGE_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.START_USAGE_BY_SPECIFIC_DATETIME},
  {label: 'Sự kiện cụ thể', value: GIFT_TIME_OPTION_VALUE.START_USAGE_BY_SPECIFIC_EVENT},
];

const GIFT_TIME_START_USAGE_BY_SPECIFIC_EVENT_OPTIONS = [
  {label: 'Ngày nhận voucher', value: GIFT_TIME_OPTION_VALUE.START_USAGE_EVENT_BY_RECEIVED_DAY},
];

const GIFT_TIME_END_USAGE_OPTIONS = [
  {label: 'Thời gian cụ thể', value: GIFT_TIME_OPTION_VALUE.END_USAGE_BY_SPECIFIC_DATETIME},
  {label: 'Khoảng thời gian', value: GIFT_TIME_OPTION_VALUE.END_USAGE_BY_DURATION},
  {label: 'Mốc thời gian', value: GIFT_TIME_OPTION_VALUE.END_USAGE_BY_TIMELINE},
];

const GIFT_TIME_END_USAGE_BY_DURATION_OPTIONS = [
  {label: 'Ngày', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_DATE},
  {label: 'Tuần', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_WEEK},
  {label: 'Tháng', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_MONTH},
  {label: 'Năm', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_YEAR},
];

const GIFT_TIME_END_USAGE_BY_TIMELINE_OPTIONS = [
  {label: 'Cuối ngày nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_DATE},
  {label: 'Cuối tuần nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_WEEK},
  {label: 'Cuối tháng nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_MONTH},
  {label: 'Cuối năm nhận', value: GIFT_TIME_OPTION_VALUE.END_USAGE_DURATION_BY_YEAR},
];

const RETENTION_RATE_RANGE_OPTIONS = [
  {label: 'Trong vòng 7 ngày', value: 'return_7'},
  {label: 'Trong vòng 14 ngày', value: 'return_14'},
  {label: 'Trong vòng 30 ngày', value: 'return_30'},
  {label: 'Trong vòng 60 ngày', value: 'return_60'},
  {label: 'Trong vòng 90 ngày', value: 'return_90'},
];

const ENDPOINT_EXPORT_REPORT = {
  RETENTION_RATE: '/api/dashboard/Export/retention-rate/export',
  THONG_TIN_THANH_VIEN: '/api/dashboard/Export/thongtinthanhvien/export',
  MUA_HANG_TICH_DIEM: '/api/dashboard/Export/muahangtichdiem/export',
  TICH_VA_SU_DUNG_DIEM: '/api/dashboard/Export/tichvasudungdiem/export',
  DOI_VA_SU_DUNG_QUA: '/api/dashboard/Export/doivasudungqua/export',
};

const API_KEY_EXPORT_REPORT = 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855';

const ERROR_CODE_EXPORT_REPORT = {
  NO_DATA: 'no_data',
  ROW_LIMIT_EXCEEDED: 'row_limit_exceeded',
};

const CONTENT_ATTACH_REFERENCES_TYPE = {
  GIFT: 1,
  GAME: 2,
};

const CUSTOMER_FILTER_ADD_TYPE_VALUES = {
  CRITERIA: false,
  MANUAL: true,
};

const CUSTOMER_FILTER_ADD_TYPE_OPTIONS = [
  {label: 'Theo tiêu chí', value: CUSTOMER_FILTER_ADD_TYPE_VALUES.CRITERIA},
  {label: 'Thủ công', value: CUSTOMER_FILTER_ADD_TYPE_VALUES.MANUAL},
];

const GENDER_OPTIONS = [
  {label: 'Nam', value: 'M'},
  {label: 'Nữ', value: 'F'},
  {label: 'Khác', value: 'O'},
];

const BIRTH_MONTH_OPTIONS = [
  {label: 'Tháng 1', value: 1},
  {label: 'Tháng 2', value: 2},
  {label: 'Tháng 3', value: 3},
  {label: 'Tháng 4', value: 4},
  {label: 'Tháng 5', value: 5},
  {label: 'Tháng 6', value: 6},
  {label: 'Tháng 7', value: 7},
  {label: 'Tháng 8', value: 8},
  {label: 'Tháng 9', value: 9},
  {label: 'Tháng 10', value: 10},
  {label: 'Tháng 11', value: 11},
  {label: 'Tháng 12', value: 12},
];

const YES_NO_OPTIONS = [
  {
    label: 'Có',
    value: true,
  },
  {
    label: 'Không',
    value: false,
  },
];

const NOTIFICATION_CHANNELS = {
  SMS: 'SMS',
  ZNS: 'ZNS',
  DPOINT_APP: 'DPOINT_APP',
};

const NOTIFICATION_CHANNEL_OPTIONS = [
  {
    label: 'SMS',
    value: NOTIFICATION_CHANNELS.SMS,
  },
  {
    label: 'ZNS',
    value: NOTIFICATION_CHANNELS.ZNS,
  },
  {
    label: 'App DPoint',
    value: NOTIFICATION_CHANNELS.DPOINT_APP,
  },
];
const ATTACHMENT_TYPE_OPTIONS = [
  {
    label: 'Quà tặng',
    value: 1,
  },
  {
    label: 'Trò chơi',
    value: 2,
  },
  {
    label: 'Nhiệm vụ',
    value: 3,
  },
  {label: 'Nội dung', value: 4},
];

const CONTENT_TEMPLATE_POSITION_CTA = {
  DPOINT_APP: 0,
  WEB: 1,
};

const POSITION_CTA_OPTIONS = [
  {
    label: 'Đến vị trí cụ thể trên app DPoint',
    value: CONTENT_TEMPLATE_POSITION_CTA.DPOINT_APP,
  },
  {
    label: 'Đến trang web',
    value: CONTENT_TEMPLATE_POSITION_CTA.WEB,
  },
];

const AUTOMATIC_INTERACTION_STATUS_OPTIONS = [
  {
    label: 'Bản nháp',
    value: AutomaticInteractionStatus.DRAFT,
  },
  {
    label: 'Chờ duyệt',
    value: AutomaticInteractionStatus.PENDING,
  },
  {
    label: 'Đang duyệt',
    value: AutomaticInteractionStatus.WAITING_FOR_APPROVAL,
  },
  {
    label: 'Đã duyệt',
    value: AutomaticInteractionStatus.APPROVED,
  },
  {
    label: 'Từ chối',
    value: AutomaticInteractionStatus.REJECTED,
  },
  {
    label: 'Đã xoá',
    value: AutomaticInteractionStatus.DELETED,
  },
];

const AUTOMATIC_INTERACTION_VALIDITY_PERIOD = {
  LIMITED: 0,
  FOREVER: 1,
};

const AUTOMATIC_INTERACTION_VALIDITY_PERIOD_OPTIONS = [
  {
    label: 'Có thời hạn',
    value: AUTOMATIC_INTERACTION_VALIDITY_PERIOD.LIMITED,
  },
  {
    label: 'Vĩnh viễn',
    value: AUTOMATIC_INTERACTION_VALIDITY_PERIOD.FOREVER,
  },
];

const AUTO_INTERACTION_MAX_PER_USER_PERIOD_TYPE = [
  {label: 'Ngày', value: 'DAY'},
  {label: 'Tháng', value: 'MONTH'},
  {label: 'Năm', value: 'YEAR'},
  {label: 'Toàn bộ thời gian hiệu lực', value: 'VALIDITY_PERIOD'},
];

const TIME_TYPE_1 = {
  MILLISECOND: 'Millisecond',
  SECOND: 'Second',
  MINUTE: 'Minute',
  HOUR: 'Hour',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
};

const AUTO_INTERACTION_ACTIVITY_PERIOD_TYPE = [
  {label: 'Giờ', value: TIME_TYPE_1.HOUR},
  {label: 'Ngày', value: TIME_TYPE_1.DAY},
  {label: 'Tháng', value: TIME_TYPE_1.MONTH},
  {label: 'Năm', value: TIME_TYPE_1.YEAR},
];

const ACTIVITY_TYPE = {
  ACTIVE: 'ACTIVE',
  REMIND: 'REMIND',
};

const AUTO_INTERACTION_SENDING_TIME_TYPE = {
  SEND_RIGHT_AFTER_EVENT: {label: 'Gửi ngay khi hoạt động diễn ra', value: 0},
  SEND_AFTER_EVENT: {label: 'Sau khi hoạt động diễn ra', value: 1},
  SEND_DAY_OF_WEEK: {label: 'Thứ cụ thể trong tuần', value: 2},
  SEND_DAY_OF_MONTH: {label: 'Ngày cụ thể trong tháng', value: 3},
  SEND_DAY_OF_YEAR: {label: 'Ngày cụ thể trong năm', value: 4},
};

const AUTO_INTERACTION_SENDING_TIME_PERIOD_TYPE = [
  {label: 'Phút', value: TIME_TYPE_1.MINUTE},
  {label: 'Giờ', value: TIME_TYPE_1.HOUR},
  {label: 'Ngày', value: TIME_TYPE_1.DAY},
  {label: 'Tháng', value: TIME_TYPE_1.MONTH},
  {label: 'Năm', value: TIME_TYPE_1.YEAR},
];

const DAY_OF_WEEK_OPTIONS = [
  {label: 'Thứ hai', value: 1, order: 1},
  {label: 'Thứ ba', value: 2, order: 2},
  {label: 'Thứ tư', value: 3, order: 3},
  {label: 'Thứ năm', value: 4, order: 4},
  {label: 'Thứ sáu', value: 5, order: 5},
  {label: 'Thứ bảy', value: 6, order: 6},
  {label: 'Chủ nhật', value: 0, order: 7},
];

const DAY_OF_MONTH_OPTIONS = [
  {
    label: 'Ngày 1',
    value: 1,
  },
  {
    label: 'Ngày 2',
    value: 2,
  },
  {
    label: 'Ngày 3',
    value: 3,
  },
  {
    label: 'Ngày 4',
    value: 4,
  },
  {
    label: 'Ngày 5',
    value: 5,
  },
  {
    label: 'Ngày 6',
    value: 6,
  },
  {
    label: 'Ngày 7',
    value: 7,
  },
  {
    label: 'Ngày 8',
    value: 8,
  },
  {
    label: 'Ngày 9',
    value: 9,
  },
  {
    label: 'Ngày 10',
    value: 10,
  },
  {
    label: 'Ngày 11',
    value: 11,
  },
  {
    label: 'Ngày 12',
    value: 12,
  },
  {
    label: 'Ngày 13',
    value: 13,
  },
  {
    label: 'Ngày 14',
    value: 14,
  },
  {
    label: 'Ngày 15',
    value: 15,
  },
  {
    label: 'Ngày 16',
    value: 16,
  },
  {
    label: 'Ngày 17',
    value: 17,
  },
  {
    label: 'Ngày 18',
    value: 18,
  },
  {
    label: 'Ngày 19',
    value: 19,
  },
  {
    label: 'Ngày 20',
    value: 20,
  },
  {
    label: 'Ngày 21',
    value: 21,
  },
  {
    label: 'Ngày 22',
    value: 22,
  },
  {
    label: 'Ngày 23',
    value: 23,
  },
  {
    label: 'Ngày 24',
    value: 24,
  },
  {
    label: 'Ngày 25',
    value: 25,
  },
  {
    label: 'Ngày 26',
    value: 26,
  },
  {
    label: 'Ngày 27',
    value: 27,
  },
  {
    label: 'Ngày 28',
    value: 28,
  },
  {
    label: 'Ngày 29',
    value: 29,
  },
  {
    label: 'Ngày 30',
    value: 30,
  },
  {
    label: 'Ngày 31',
    value: 31,
  },
];

const INTERACTION_MAX_LENGTH = {
  MAX_LENGTH_TEMPLATE_NAME: 128,
  MAX_LENGTH_DPOINT_APP_TITLE: 50,
  MAX_LENGTH_DPOINT_APP_DESC_TABLE: 15,
  MAX_LENGTH_DPOINT_APP_1_CTA_NAME: 30,
  MAX_LENGTH_DPOINT_APP_2_CTA_NAME: 15,
};

const INTERACTION_REGEX = {
  PARAM_REGEX: /\[\[(.*?)\]\]/g,
  PATTERN: /\[{2}([A-z]|\])*$/,
};

const INTERACTION_VIEW_TYPE = {
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

const INTERACTION_CONTENT_SOURCE = {
  TEMPLATE: 0,
  CUSTOM: 1,
};

const NUMBER_OF_CTA_BUTTON_OPTIONS = [
  {
    label: '1 nút',
    value: true,
  },
  {
    label: '2 nút',
    value: false,
  },
];

const APPLICABLE_MERCHANT_OPTIONS = [
  {
    label: 'Tất cả doanh nghiệp',
    value: true,
  },
  {
    label: 'Doanh nghiệp cụ thể',
    value: false,
  },
];

const AUTO_INTERACTION_TABS = {
  OBJECT_SETTING_TAB: 'OBJECT_SETTING_TAB',
  ACTIVITY_TAB: 'ACTIVITY_TAB',
  SENDING_TIME_TAB: 'SENDING_TIME_TAB',
  CHANNEL_CONTENT_TAB: 'CHANNEL_CONTENT_TAB',
};

const INTERACTION_SEND_CHANNEL_OPTIONS = [
  {
    label: 'Gửi tất cả các kênh',
    value: true,
  },
  {
    label: 'Gửi theo độ ưu tiên (Gửi kênh ưu tiên cao, thất bại sẽ gửi kênh ưu tiên thấp hơn)',
    value: false,
  },
];

const INTERACTION_CONTENT_SOURCE_OPTIONS = [
  {
    label: 'Theo mẫu',
    value: INTERACTION_CONTENT_SOURCE.TEMPLATE,
  },
  {
    label: 'Tuỳ chỉnh',
    value: INTERACTION_CONTENT_SOURCE.CUSTOM,
  },
];

const ENTER_DEEP_LINK_OPTION = {
  label: 'Nhập deep link màn hình chi tiết',
  value: 'ENTER_DEEP_LINK',
};

const DEEPLINK_TABNAME_DATA = {
  MERCHANT_SCREEN: 'MERCHANT_SCREEN',
  DETAIL_SCREEN: 'DETAIL_SCREEN',
  FUNCTION_SCREEN: 'FUNCTION_SCREEN',
  HOME_PAGE: 'HOME_PAGE',
};

const ACCOUNTS_SEND_MESSAGES_TAB_KEYS = {
  SMS: 'SMS',
  ZALO_OA: 'ZNS',
};

const ACCOUNTS_SEND_MESSAGES_TAB_ITEMS = [
  {
    title: 'SMS',
    id: ACCOUNTS_SEND_MESSAGES_TAB_KEYS.SMS,
  },
  {
    title: 'Zalo OA',
    id: ACCOUNTS_SEND_MESSAGES_TAB_KEYS.ZALO_OA,
  },
];

const SERVICE_PROVIDERS = {
  GapOne: 'GapOne',
  VietGuys: 'VietGuys',
};

const SERVICE_PROVIDER_OPTIONS = [
  {label: 'GapOne', value: SERVICE_PROVIDERS.GapOne},
  // {label: 'VietGuys', value: SERVICE_PROVIDERS.VietGuys},
];

const ACCOUNTS_SEND_MESSAGES_VALUE_TYPE = {
  String: 'String',
};

export {
  API_ENDPOINTS_HOST,
  API_METHOD,
  STORAGE_KEY,
  MERCHANT_PERMISSIONS,
  STORE_TYPE,
  CLASSIFIED_GIFT,
  DELIVERY_TRANSACTION_PICKUP_TYPE,
  DELIVERY_TRANSACTION_SERVICE_NAME,
  MERCHANT_NAME_OF_STRONGBOW,
  PROCESSING_STATUS,
  NOTIFICATION_TYPE_ENDPOINT,
  STATUS_COLOR,
  REDIRECT_PATHS,
  AUDIT_LOG_TYPE,
  MERCHANT_PORTAL_DASHBOARD_TYPE,
  MONTH_OPTIONS,
  RETENTION_RATE_RANGE_OPTIONS,
  GIFT_TIME_OPTION_VALUE,
  GIFT_TIME_START_REDEEM_OPTIONS,
  GIFT_TIME_END_REDEEM_OPTIONS,
  GIFT_TIME_START_USAGE_OPTIONS,
  GIFT_TIME_START_USAGE_BY_SPECIFIC_EVENT_OPTIONS,
  GIFT_TIME_END_USAGE_OPTIONS,
  GIFT_TIME_END_USAGE_BY_DURATION_OPTIONS,
  GIFT_TIME_END_USAGE_BY_TIMELINE_OPTIONS,
  ENDPOINT_EXPORT_REPORT,
  API_KEY_EXPORT_REPORT,
  ERROR_CODE_EXPORT_REPORT,
  CONTENT_ATTACH_REFERENCES_TYPE,
  CUSTOMER_FILTER_ADD_TYPE_VALUES,
  CUSTOMER_FILTER_ADD_TYPE_OPTIONS,
  GENDER_OPTIONS,
  BIRTH_MONTH_OPTIONS,
  YES_NO_OPTIONS,
  NOTIFICATION_CHANNELS,
  NOTIFICATION_CHANNEL_OPTIONS,
  ATTACHMENT_TYPE_OPTIONS,
  CONTENT_TEMPLATE_POSITION_CTA,
  POSITION_CTA_OPTIONS,
  AUTOMATIC_INTERACTION_STATUS_OPTIONS,
  AUTOMATIC_INTERACTION_VALIDITY_PERIOD,
  AUTOMATIC_INTERACTION_VALIDITY_PERIOD_OPTIONS,
  AUTO_INTERACTION_MAX_PER_USER_PERIOD_TYPE,
  TIME_TYPE_1,
  AUTO_INTERACTION_ACTIVITY_PERIOD_TYPE,
  ACTIVITY_TYPE,
  AUTO_INTERACTION_SENDING_TIME_TYPE,
  AUTO_INTERACTION_SENDING_TIME_PERIOD_TYPE,
  DAY_OF_WEEK_OPTIONS,
  DAY_OF_MONTH_OPTIONS,
  INTERACTION_MAX_LENGTH,
  INTERACTION_REGEX,
  INTERACTION_VIEW_TYPE,
  INTERACTION_CONTENT_SOURCE,
  NUMBER_OF_CTA_BUTTON_OPTIONS,
  APPLICABLE_MERCHANT_OPTIONS,
  AUTO_INTERACTION_TABS,
  INTERACTION_SEND_CHANNEL_OPTIONS,
  INTERACTION_CONTENT_SOURCE_OPTIONS,
  ENTER_DEEP_LINK_OPTION,
  DEEPLINK_TABNAME_DATA,
  ACCOUNTS_SEND_MESSAGES_TAB_KEYS,
  ACCOUNTS_SEND_MESSAGES_TAB_ITEMS,
  SERVICE_PROVIDERS,
  SERVICE_PROVIDER_OPTIONS,
  ACCOUNTS_SEND_MESSAGES_VALUE_TYPE,
};
